/*
insane https://github.com/facebook/create-react-app/issues/11771#issuecomment-1323967933
*/
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }


html {
  font-size: 62.5%;
} /* =10px */
/* see also https://v4.mui.com/customization/typography/#font-size */
/* see also https://marmelab.com/react-admin/Theming.html */
/* see also https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */
/* 
  
  A ThemeProvider is not enough,
  <ThemeProvider theme={MyTheme}> only works outside React Admin
  
  must also pass themew to React Admin: 
  <Admin theme={MyTheme} dataProvider={dataProvider(Config)}>

*/

@font-face {
  font-family: 'FuturaPTLight';
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.eot'); /* IE9 Compat Modes */
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTLight.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: lighter;
}
@font-face {
  font-family: 'FuturaPTBook';
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.eot'); /* IE9 Compat Modes */
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTBook.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: bold;
}
@font-face {
  font-family: 'FuturaPTMedium';
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.eot'); /* IE9 Compat Modes */
  src: url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://res.cloudinary.com/onlyro/raw/upload/v1492084472/fonts/futura-pt/FuturaPTMedium.svg#svgFontName')
      format('svg'); /* Legacy iOS */
  font-weight: medium;
}

body {
  margin: 0;
  font-family: FuturaPTLight, 'Century Gothic', CenturyGothic, 'Apple Gothic',
    AppleGothic, 'URW Gothic L', 'Avant Garde', sans-serif;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
